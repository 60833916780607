import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import useLocalisation from '../helper/useLocalisation'
import {
  Banner,
  BodyText,
  ContentPadding,
  CreamBackground,
  ExpandSection,
  FAQBody,
  Heading,
  ImageContainer,
  MaxWidth,
  SplitContainer,
  SplitItem,
  StaticContainer,
  StyledImage,
  TopMagin,
  VerticalSplit,
  VerticalSplitItem,
} from '../styles/static.js'

const WhyPage = ({ data }) => {
  const stack = data.Comfort.childImageSharp.gatsbyImageData
  const laundry = data.Laundry.childImageSharp.gatsbyImageData
  const shoot1 = data.Shoot1.childImageSharp.gatsbyImageData
  const carrier1 = data.Carrier1.childImageSharp.gatsbyImageData
  const carrier2 = data.Carrier2.childImageSharp.gatsbyImageData
  const Q = useLocalisation()
  const FAQData = [
    {
      title: Q('battery-faq-title-1'),
      body: <FAQBody>{Q('battery-faq-body-1')}</FAQBody>,
    },
    {
      title: Q('battery-faq-title-2'),
      body: Q('battery-faq-body-2'),
    },
  ]

  return (
    <Layout>
      <SEO
        title={'The Ejectable Battery'}
        description={
          'The July battery power-bank is a 37WH lithium-polymer battery. It is allowed for carry-on without airline approval for any flight in the world.'
        }
      />
      <StaticContainer>
        <TopMagin />
        <CreamBackground>
          <ContentPadding>
            <SplitContainer center={true}>
              <SplitItem>
                <ImageContainer>
                  <StyledImage image={stack} />
                </ImageContainer>
              </SplitItem>
              <SplitItem>
                <Heading main={true}> {Q('the-ejectable-battery')} </Heading>
                <br />
                <BodyText>{Q('battery-subheading')}</BodyText>
              </SplitItem>
            </SplitContainer>
          </ContentPadding>
        </CreamBackground>
        <Banner images={true}>
          <StyledImage image={carrier1} />
          <StyledImage image={carrier2} />
        </Banner>
        <ContentPadding>
          <SplitContainer>
            <SplitItem noPadding={true}>
              <VerticalSplit>
                <VerticalSplitItem>
                  <Heading> {Q('using-the-power-bank')} </Heading>
                  <BodyText>{Q('using-the-power-bank-body')}</BodyText>
                </VerticalSplitItem>
                <VerticalSplitItem>
                  <ImageContainer>
                    <StyledImage image={shoot1} />
                  </ImageContainer>
                </VerticalSplitItem>
              </VerticalSplit>
            </SplitItem>
            <SplitItem noPadding={true}>
              <VerticalSplit>
                <VerticalSplitItem>
                  <ImageContainer>
                    <StyledImage image={laundry} />
                  </ImageContainer>
                </VerticalSplitItem>
                <VerticalSplitItem>
                  <BodyText>{Q('using-the-power-bank-body-2')}</BodyText>
                </VerticalSplitItem>
              </VerticalSplit>
            </SplitItem>
          </SplitContainer>
        </ContentPadding>
        <CreamBackground grey={true}>
          <ContentPadding>
            <SplitContainer>
              <SplitItem>
                {Q('battery-info-1', { display: 'block !important' }, true)}
              </SplitItem>
              <SplitItem>
                {Q('battery-info-2', { display: 'block !important' }, true)}
              </SplitItem>
            </SplitContainer>
          </ContentPadding>
        </CreamBackground>
        <ContentPadding>
          <MaxWidth>
            <ContentPadding bottom={true}>
              {FAQData.map((item) => {
                return <ExpandSection title={item.title} body={item.body} />
              })}
            </ContentPadding>
          </MaxWidth>
        </ContentPadding>
      </StaticContainer>
    </Layout>
  )
}

export default WhyPage

export const query = graphql`
  {
    Comfort: file(relativePath: { eq: "battery/battery01.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    Laundry: file(relativePath: { eq: "battery/battery.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    Shoot1: file(relativePath: { eq: "battery/battery02.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    Carrier1: file(relativePath: { eq: "battery/carriers1.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    Carrier2: file(relativePath: { eq: "battery/carriers2.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
